import {
  createEffect,
  createMemo,
  createRenderEffect,
  DEV,
  lazy,
  Show,
  Suspense,
} from "solid-js";
import FirstScreen from "~/components/Program/Components/FirstScreen";
import StackBar from "~/components/Program/Components/StackBar";
import Tabs from "~/components/Program/Components/Tabs";
import Presentation from "~/components/Program/Components/Presentation";
import Baseline from "~/components/Program/Components/Baseline";
import CommercialOffer from "~/components/shared/CommercialOffer";
import Grid from "~/components/Program/Components/Grid";
import SalesState from "~/components/Program/Components/SalesState";
import * as helper from "~/utils/helper_program";
import Block3D from "~/components/Program/Components/Block3D";
import Cogehome3D from "~/components/Program/Components/Cogehome3D";
import CityscanBlock from "~/components/Program/Components/CityscanBlock";
import { hasNoEscape } from "~/utils/no_escape";
import InvestBlock from "~/components/Program/Components/InvestBlock";
import SalesOffice from "~/components/Program/Components/SalesOffice";
import Tailored from "~/components/Program/Components/Tailored";
import ProgramsNearby from "~/components/Program/Components/ProgramsNearby";
import ComplimentaryContents from "~/components/Program/Components/ComplimentaryContents";
import { useDrupalSettingsContext } from "~/contexts/DrupalSettingsContext";
import Breadcrumb from "~/components/shared/Breadcrumb";
import { urlRs } from "~/utils/url";
import OtherCities from "~/components/Program/Components/OtherCities";
import {
  geographyBreadcrumbItems,
  hasLMNPVatExSlice,
  shouldShowInPageForm,
} from "~/utils/helper_program";
import { useLotActive } from "~/contexts/LotActiveContext";
import GridPromo from "~/components/Program/Components/GridPromo";
import ProgramFormInPage from "~/components/Program/Components/ProgramFormInPage";

import "./ProgramCommon.css";

import type { ProgramWrapper } from "~/utils/program_wrapper";
import type { BreadcrumbItem } from "~/types/breadcrumb";
import ProgramAgency from "~/components/Program/Components/ProgramAgency";
import { useEventsContext } from "~/contexts/EventsContext";

const LotDrawer = lazy(() => import("./Components/LotDrawer"));

type ProgramProps = {
  wrapper: ProgramWrapper;
};

export function ProgramStd(props: ProgramProps) {
  const settingsContext = useDrupalSettingsContext();

  const breadcrumbItems = createMemo(() => {
    const items: BreadcrumbItem[] = [];

    const root: BreadcrumbItem = {
      href: urlRs("search", "/programme-immobilier-neuf/"),
      text: "Programmes immobiliers neufs",
    };
    items.push(root);

    const geographiesTemp: BreadcrumbItem[] = [];
    const geographies: BreadcrumbItem[] = geographyBreadcrumbItems(
      props.wrapper.program.field_geography,
      geographiesTemp,
    ).toReversed();

    geographies.forEach((geography: BreadcrumbItem) => {
      items.push(geography);
    });

    const lastBreadCrumbItem: BreadcrumbItem = {
      text: props.wrapper.program?.title,
    };
    items.push(lastBreadCrumbItem);

    return items;
  });

  const [lotProvider] = useLotActive();

  if (DEV) {
    createEffect(() => {
      if (lotProvider.lot) {
        console.log("Lot current ID:", lotProvider.lot.id);
      }
    });
  }

  createRenderEffect(() => {
    const [, { setTemplate }] = useEventsContext();
    // By default, template is set to "program-std"
    if (helper.isPreview(props.wrapper)) {
      setTemplate("program-preview");
    }
  });

  return (
    <>
      <article class="node-program">
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer wrapper={lotProvider.wrapper!} lot={lotProvider.lot!} />
          </Suspense>
        </Show>

        <FirstScreen wrapper={props.wrapper} />
        <StackBar wrapper={props.wrapper} />

        <div class="program-sections">
          <div class="content-part">
            <Tabs wrapper={props.wrapper} />

            <Presentation wrapper={props.wrapper} />

            <Baseline withAnimation={!!props.wrapper.program?.animation} />

            <GridPromo wrapper={props.wrapper} />

            <section id="prix-plans">
              <Show when={props.wrapper.program?.animation}>
                <CommercialOffer animation={props.wrapper.program.animation!} />
              </Show>
              <Grid wrapper={props.wrapper} />
            </section>

            <Show when={shouldShowInPageForm(props.wrapper)}>
              <ProgramFormInPage wrapper={props.wrapper} />
            </Show>

            <Show
              when={
                props.wrapper.program.field_program_type
                  ?.drupal_internal__tid !==
                settingsContext.program_types.terrains
              }
            >
              <SalesState wrapper={props.wrapper} />
            </Show>

            <Show
              when={
                helper.virtualVisitsLength(props.wrapper) > 0 &&
                !props.wrapper.program.field_is_cogehome3d
              }
            >
              <Block3D wrapper={props.wrapper} />
            </Show>
            <Show
              when={
                helper.virtualVisitsLength(props.wrapper) > 0 &&
                props.wrapper.program.field_is_cogehome3d
              }
            >
              <Cogehome3D wrapper={props.wrapper} />
            </Show>

            <Show
              when={
                !props.wrapper.program.field_address_hide &&
                settingsContext.cityscan_is_enabled
              }
            >
              <CityscanBlock wrapper={props.wrapper} />
            </Show>

            <Show
              when={
                !helper.isPreview(props.wrapper) &&
                (props.wrapper.program.field_disp_grid_pinel ||
                  props.wrapper.program.field_disp_grid_pinel_plus ||
                  props.wrapper.program.field_disp_grid_lmnp_vat_ex ||
                  props.wrapper.program.field_disp_grid_lmnp_vat_inc) &&
                props.wrapper.program.field_program_type
                  ?.drupal_internal__tid !==
                  settingsContext.program_types.terrains
              }
            >
              <InvestBlock wrapper={props.wrapper} />
            </Show>

            <SalesOffice wrapper={props.wrapper} />
          </div>
        </div>

        <Tailored onlyFinance={hasLMNPVatExSlice(props.wrapper)} />

        <Show when={!hasNoEscape()}>
          <Show when={props.wrapper.program.other_programs_nearby.length > 0}>
            <ProgramsNearby wrapper={props.wrapper} />
          </Show>

          <Show when={props.wrapper.program.other_cities.length > 0}>
            <OtherCities
              cities={props.wrapper.program.other_cities}
              displayAlone={
                props.wrapper.program.other_programs_nearby.length === 0
              }
            />
          </Show>
        </Show>

        <ProgramAgency />

        <Show when={!hasNoEscape()}>
          <ComplimentaryContents wrapper={props.wrapper} />
        </Show>
      </article>
      <Breadcrumb items={breadcrumbItems()} />
    </>
  );
}
