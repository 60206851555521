import type { ProgramWrapper } from "~/utils/program_wrapper";
import { geographyBreadcrumbItems } from "~/utils/helper_program";
import type { BreadcrumbItem } from "~/types/breadcrumb";
import {
  createEffect,
  createMemo,
  createRenderEffect,
  DEV,
  Show,
  Suspense,
} from "solid-js";
import { urlRs } from "~/utils/url";
import FirstScreen from "~/components/Program/Components/FirstScreen";
import Presentation from "~/components/Program/Components/Presentation";
import Breadcrumb from "~/components/shared/Breadcrumb";
import { hasNoEscape } from "~/utils/no_escape";
import ProgramsNearbyOutOfStock from "~/components/Program/Components/ProgramsNearbyOutOfStock";
import { useLotActive } from "~/contexts/LotActiveContext";
import LotDrawer from "~/components/Program/Components/LotDrawer";

import "./ProgramCommon.css";
import { useEventsContext } from "~/contexts/EventsContext";
import * as helper from "~/utils/helper_program";

type ProgramProps = {
  wrapper: ProgramWrapper;
};

export function ProgramOutOfStock(props: ProgramProps) {
  const breadcrumbItems = createMemo(() => {
    const items: BreadcrumbItem[] = [];

    const root: BreadcrumbItem = {
      href: urlRs("search", "/programme-immobilier-neuf/"),
      text: "Programmes immobiliers neufs",
    };
    items.push(root);

    const geographiesTemp: BreadcrumbItem[] = [];
    const geographies: BreadcrumbItem[] = geographyBreadcrumbItems(
      props.wrapper.program.field_geography,
      geographiesTemp,
    ).toReversed();

    geographies.forEach((geography: BreadcrumbItem) => {
      items.push(geography);
    });

    const lastBreadCrumbItem: BreadcrumbItem = {
      text: props.wrapper.program?.title,
    };
    items.push(lastBreadCrumbItem);

    return items;
  });

  const [lotProvider] = useLotActive();

  if (DEV) {
    createEffect(() => {
      if (lotProvider.lot) {
        console.log("program current ID:", lotProvider.wrapper?.program.id);
        console.log("Lot current ID:", lotProvider.lot.id);
      }
    });
  }

  createRenderEffect(() => {
    const [, { setTemplate }] = useEventsContext();
    // By default, template is set to "program-std"
    if (helper.isOutOfStock(props.wrapper)) {
      setTemplate("program-out-of-stock");
    }
  });

  return (
    <>
      <article class="node-program">
        <Show when={lotProvider.wrapper && lotProvider.lot} keyed>
          <Suspense>
            <LotDrawer wrapper={lotProvider.wrapper!} lot={lotProvider.lot!} />
          </Suspense>
        </Show>

        <FirstScreen wrapper={props.wrapper} />

        <div class="program-sections">
          <div class="content-part">
            <Presentation wrapper={props.wrapper} />
          </div>
        </div>

        <Show
          when={
            !hasNoEscape() &&
            props.wrapper.program.other_programs_geo_level_1.length > 0
          }
        >
          <ProgramsNearbyOutOfStock wrapper={props.wrapper} />
        </Show>
      </article>
      <Breadcrumb items={breadcrumbItems()} />
    </>
  );
}
