import "./Baseline.css";

export default function Baseline(props: { withAnimation: boolean }) {
  return (
    <>
      <div
        class="cogedim-baseline"
        classList={{ "without-animation": !props.withAnimation }}
      >
        <p>
          <strong>
            <span>Cogedim s’engage</span>
          </strong>{" "}
          pour votre pouvoir d'acheter.
        </p>
      </div>
    </>
  );
}
